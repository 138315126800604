<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_36087_105945"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0H23.9997V23.9999H0V0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_36087_105945)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.846 19.997H16.853H16.693C16.685 19.92 16.682 19.856 16.682 19.792V14.424C16.681 14.002 16.653 13.585 16.544 13.175C16.416 12.703 16.181 12.31 15.697 12.109C15.101 11.857 14.017 11.929 13.518 12.546C13.237 12.895 13.098 13.298 13.05 13.734C13.002 14.109 12.984 14.485 12.984 14.86C12.971 16.511 12.982 18.166 12.982 19.822C12.982 19.997 12.973 19.997 12.796 19.997C11.797 19.997 10.805 19.997 9.81 19.999C9.686 20 9.652 19.967 9.652 19.841C9.655 16.384 9.655 12.923 9.652 9.464C9.652 9.345 9.678 9.301 9.809 9.301C10.773 9.307 11.741 9.307 12.705 9.301C12.826 9.301 12.849 9.338 12.849 9.449C12.846 9.88 12.847 10.31 12.874 10.755C12.894 10.73 12.911 10.703 12.936 10.677C13.517 9.737 14.39 9.236 15.461 9.081C16.097 8.993 16.73 9.036 17.357 9.183C18.56 9.465 19.323 10.221 19.686 11.383C19.86 11.938 19.963 12.515 19.981 13.094C20.011 13.858 20.013 14.616 20.014 15.379C20.018 16.864 20.015 18.35 20.015 19.829C20.015 19.997 20.015 19.997 19.846 19.997ZM5.903 7.861C4.84 7.861 3.985 7 3.985 5.929C3.985 4.855 4.839 4.001 5.91 4C6.981 4 7.85 4.863 7.846 5.933C7.845 7.001 6.976 7.861 5.903 7.861ZM7.579 14.662V19.825C7.579 19.999 7.579 19.999 7.407 19.999C6.405 19.999 5.401 19.999 4.4 20C4.276 20.001 4.237 19.98 4.237 19.845C4.242 16.387 4.242 12.926 4.242 9.465C4.242 9.306 4.242 9.306 4.408 9.306H7.405C7.58 9.306 7.58 9.306 7.579 9.48V14.662ZM20 0H4C1.791 0 0 1.791 0 4V20C0 22.209 1.791 24 4 24H20C22.209 24 24 22.209 24 20V4C24 1.791 22.209 0 20 0Z"
        fill="#4E99E3"
      />
    </g>
  </svg>
</template>
